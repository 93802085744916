import { createApp, nextTick } from "vue"
import { createPinia } from "pinia"

const LanguageSwitch = () => import("@/components/Frontend/LanguageSwitch.vue")

;(() => {
    const pinia = createPinia()

    const elements = {
        languageSwitch: document.querySelector(".language-switch__text"),
    }

    const languageSwitch = document.querySelector(".language-switch")
    const shortLinkNotify = document.getElementById("copy-link-notify")
    const adminBtnCopyLink = document.querySelector("#copyPageUrl")
    const checkUser = document.body.classList.contains("logged-in")

    if (languageSwitch) {
        if ("requestIdleCallback" in window) {
            requestIdleCallback(() => {
                //initFooterApp()
                initThemeSwitcher()
            })
        } else {
            setTimeout(() => {
                //initFooterApp()
                initThemeSwitcher()
            }, 1000)
        }
    } else {
        initThemeSwitcher()
    }
    function bindSwitcherBtn() {
        const switchIcon = elements.languageSwitch

        let footerAppLoaded = false

        if (switchIcon) {
            switchIcon.addEventListener(
                "click",
                (e) => {
                    if (!footerAppLoaded) {
                        initFooterApp(pinia, true).then(() => {
                            footerAppLoaded = true
                            setTimeout(() => {
                                document.querySelector(".language-switch__text").click() // Повторний клік після ініціалізації
                                rebindEventListeners() // Повторне прив'язування обробників подій
                            }, 300) // Можливо, варто використовувати 0 замість 400 для негайного повторного кліку
                        })
                    } else {
                        switchIcon.click() // Повторний клік при кожному кліку
                    }
                },
                { passive: false },
            )
        }
    }

    bindSwitcherBtn()

    async function copyShortLink() {
        try {
            const input = document.createElement("input")
            const shortLink = await getShortLink()
            input.value = shortLink
            document.body.appendChild(input)
            input.select()
            document.execCommand("copy")
            document.body.removeChild(input)
            showNotification("Short link copied to clipboard!", "success")
        } catch (err) {
            showNotification("Failed to copy short link", "error")
            console.error("Error copying short link:", err)
        }
    }

    async function getShortLink() {
        const data = { url: window.location.href }
        try {
            const response = await window.globalJs.axios.post("/backend/get-short-link", data)
            const shortLink = response.data
            if (navigator.clipboard && shortLink) {
                await navigator.clipboard.writeText(shortLink)
            }
            return shortLink
        } catch (error) {
            showNotification("Error fetching short link!", "error")
            console.error("Error fetching short link:", error)
            throw error
        }
    }

    function showNotification(message, type) {
        shortLinkNotify.innerText = message
        shortLinkNotify.classList.add(type)
        shortLinkNotify.style.display = "block"
        setTimeout(() => {
            shortLinkNotify.classList.remove(type)
            shortLinkNotify.style.display = "none"
            shortLinkNotify.innerText = ""
        }, 2000)
    }

    function rebindEventListeners() {
        // Прив'язка обробників подій після ініціалізації Vue додатка
        const themeModeElement = document.getElementById("theme-mode")
        if (themeModeElement) {
            themeModeElement.addEventListener("click", (e) => {
                e.preventDefault()
                const newTheme = localStorage.getItem("theme") === "dark" ? "light" : "dark"
                const htmlElement = document.querySelector("html")
                htmlElement.classList.toggle("light-mode", newTheme === "light")
                htmlElement.classList.toggle("dark-mode", newTheme === "dark")
                localStorage.setItem("theme", newTheme)
            })
        }

        if (adminBtnCopyLink) {
            adminBtnCopyLink.addEventListener("click", copyShortLink, { passive: true })
        }

        if (!checkUser) {
            let toLogin = document.querySelector("#toLogin")
            if (toLogin) {
                toLogin.addEventListener("click", (event) => {
                    event.preventDefault()
                    localStorage.setItem("to_licence", "1")
                    document.querySelector("#btn-login").click()
                })
            }
        }

        document.querySelectorAll(".site-footer .footer-main-linkbox").forEach((linkBox, index) => {
            linkBox.addEventListener(
                "click",
                () => {
                    if (window.matchMedia("(max-width: 767px)").matches) {
                        linkBox.classList.toggle("expanded")
                    }
                },
                { passive: true },
            )
        })
    }

    function initThemeSwitcher() {
        // Check theme mode
        const htmlElement = document.querySelector("html")
        const themeModeElement = document.getElementById("theme-mode")
        const themeIconSun = themeModeElement?.querySelector(".sprite-icon-sun")
        const themeIconMoon = themeModeElement?.querySelector(".sprite-icon-moon")

        const setTheme = (theme) => {
            localStorage.setItem("theme", theme)
            htmlElement.classList.toggle("light-mode", theme === "light")
            htmlElement.classList.toggle("dark-mode", theme === "dark")
            if (themeIconSun) {
                themeIconSun.classList.toggle("d-none", theme === "light")
                themeIconMoon.classList.toggle("d-none", theme === "dark")
            }
        }

        const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches
        const isLightMode = window.matchMedia("(prefers-color-scheme: light)").matches

        if (!localStorage.getItem("theme")) {
            if (isDarkMode) {
                setTheme("dark")
            } else if (isLightMode) {
                setTheme("light")
            } else {
                console.warn("The theme is not specified")
            }
        } else {
            setTheme(localStorage.getItem("theme"))
        }

        const bindThemeMode = () => {
            if (themeModeElement) {
                themeModeElement.addEventListener("click", (e) => {
                    e.preventDefault()
                    const newTheme = localStorage.getItem("theme") === "dark" ? "light" : "dark"
                    setTheme(newTheme)
                })
            }
        }

        setTimeout(bindThemeMode, 500)
    }

    setTimeout(() => {
        rebindEventListeners() // Прив'язка обробників подій при першому завантаженні
        initThemeSwitcher()
    }, 2000)
})()

function initFooterApp(pinia, open = true) {
    return new Promise((resolve) => {
        const FooterApp = createApp({})
        FooterApp.config.compilerOptions.whitespace = "preserve"
        FooterApp.provide("open", open)
        FooterApp.component("language-switch", LanguageSwitch)
        FooterApp.mount(".site-footer")
        nextTick(() => {
            resolve()
        })
    })
}
